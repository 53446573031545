import PropTypes from "prop-types";
//
import Image from "../../image";
import DocumentIcon from "../../../assets/icons/DocumentIcon.svg";
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { useSnackbar } from "src/components/snackbar";

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({
  file,
  setLoaders = () => {},
  loaders = [],
  loaderIndex = -1,
}) {
  const [imgUrl, setImgUrl] = useState(file);

  const { enqueueSnackbar } = useSnackbar();

  const getBlobFromCloudfront = async (src) => {
    try {
      const response = await fetch(src);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch the file: ${response.status} ${response.statusText}`
        );
      }

      const blob = await response.blob();
      if (blob.type === "application/pdf") {
        setImgUrl(DocumentIcon);
      } else {
        setImgUrl(file);
      }
      setLoaders((prevLoaders) => {
        const updatedLoaders = [...prevLoaders];
        updatedLoaders[loaderIndex] = false;
        return updatedLoaders;
      });
    } catch (error) {
      console.error("Error downloading file:", error.message);
      enqueueSnackbar(error.message, { variant: "error" });
      setLoaders((prevLoaders) => {
        const updatedLoaders = [...prevLoaders];
        updatedLoaders[loaderIndex] = false;
        return updatedLoaders;
      });
    }
  };

  useEffect(() => {
    const type = file?.type?.split("/")[0];
    if (typeof file !== "string") {
      if (type === "image") {
        setImgUrl(file.preview);
      } else {
        setImgUrl(DocumentIcon);
      }
    } else {
      getBlobFromCloudfront(file);
    }
  }, [file]);

  if (!file) {
    return null;
  }

  return (
    <>
      {loaders?.[loaderIndex] ? (
        <LinearProgress
          color="inherit"
          sx={{
            width: 1,
            maxWidth: 360,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <Image
          alt="file preview"
          src={imgUrl}
          sx={{
            top: 8,
            left: 8,
            zIndex: 8,
            borderRadius: 1,
            position: "absolute",
            width: "calc(100% - 16px)",
            height: "calc(100% - 16px)",
          }}
        />
      )}
    </>
  );
}
